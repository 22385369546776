import { ref, reactive } from 'vue';
import usePage from '@/hooks/claim/usePage';
import { getPayoutRoundTable } from '@/API/claim/claimWS';
import { PayoutRoundListData } from '@/views/Claim/WS/types';
import { useStore } from 'vuex';
const usePayoutData = () => {
    const { state } = useStore();
    // 分页逻辑
    const { paginationObj, pageChange, sizeChange } = usePage();
    // 选择条件参数默认值设置
    const selectparams = reactive({
        roleVal: 'AFC',
        typeVal: 'WS',
        yearVal: undefined,
        monthVal: undefined,
    });
    // 设置表格
    const tableHeight = window.innerHeight - 385;
    const columns = reactive([
        { title: 'Payout Round', dataIndex: 'name', width: 280 },
        { title: 'Operation', slots: { customRender: 'operation' } },
    ]);
    const data = ref<PayoutRoundListData[]>([]);
    // 接口传参listParams
    const listParams = reactive({
        current: 0,
        size: 20,
        afcLcType: state.claim.afcLcType,
        type: 'WS',
    });
    // 获取表格数据
    const getTableData = () => {
        listParams.afcLcType =
            selectparams.roleVal.trim() !== ''
                ? selectparams.roleVal
                : state.claim.afcLcType;
        const params = listParams;
        getPayoutRoundTable({ params }).then((res) => {
            data.value = res.content;
            paginationObj.total = res.totalElements;
        });
    };
    return {
        selectparams,
        tableHeight,
        columns,
        data,
        paginationObj,
        listParams,
        getTableData,
        pageChange,
        sizeChange,
    };
};
export default usePayoutData;

import { ref, reactive } from "vue";
import * as API from "@/API/claim/claimWS";
import { ClaimListQueryParams, WSClaimTableData, PayoutRoundListData, DocType } from "@/views/Claim/WS/types";
import usePage from "@/hooks/claim/usePage";
const useRetailOverview = () => {
    // 分页逻辑
    const { paginationObj, pageChange, sizeChange } = usePage()
    /* 查询下拉选择区域 start*/
    const payoutVal = ref('')
    const statusVal = ref('')
    const selectSize = ref<number>(20)
    //下拉列表数据
    const payoutList = ref<PayoutRoundListData[]>([])
    const statusList = ref<DocType[]>([
        {label: 'All', value: ''},
        {label: 'Activated', value: 1},
        {label: 'Submitted', value: 2},
        {label: 'SICCO Checked', value: 3},
        {label: 'SIPO Confirmed', value: 7},
        {label: 'Spot Checked', value: 8},
        {label: 'Controlling Confirmed', value: 9},
        {label: 'Confirmed to AFC', value: 10},
        {label: 'AFC Confirmed', value: 11},
    ])
    const getRoundListHandle = () => {
        // 注：目前接口传参afcLcType默认AFC
        const params = {'afcLcType': 'AFC', 'type': 'WS'}
        API.getRoundList(params).then((res) => {
            const allObj = {id: '', name: 'All'}
            res.unshift(allObj)
            payoutList.value= res
        })
    }
    getRoundListHandle()
    /* 查询下拉选择区域 end*/
    // 分页
    /* 主页面列表数据表格 start */
    const claimListParams = reactive<ClaimListQueryParams>({
        afcLcType: 'AFC',
        current: 0,
        size: 20,
        status: '',
        roundId: '',
        type: 'WS'
    })
    // 设置表格
    const tableHeight = window.innerHeight - 370
    const columns = reactive([
        { title: 'Payout Round', dataIndex: 'name', width: 120},
        { title: 'VIN Count', dataIndex: 'vinCount', width: 80 },
        { title: 'Latest Upload Time', dataIndex: 'lastUplpoadTime', width: 130 },
        { title: 'Status', dataIndex: 'statusLabel', width: 100 },
        { title: 'Final Result', dataIndex: 'finalResultStr', width: 100 },
        { title: 'Operation', width: 120, slots: { customRender: 'operation' } },
    ])
    const claimListData = ref<WSClaimTableData[]>([])
    // 主数据列表接口查询
    const getTableData = () => {
        API.getClaimOverview(claimListParams).then((res) => {
            claimListData.value = res.content
            paginationObj.total = res.totalElements
        })
    }
    /* 主页面列表数据表格 end */
    const getTableDateHandle = (val: any, key: string) => {
        if (key === 'afcLcType') {
            claimListParams.roundId = val;
        } else if (key === 'status') {
            claimListParams.status = val;
        }
        getTableData()
    }
    return {
        payoutVal,
        statusVal,
        selectSize,
        payoutList,
        statusList,
        paginationObj,
        claimListParams,
        tableHeight,
        columns,
        claimListData,
        getTableData,
        pageChange,
        sizeChange,
        getTableDateHandle,
        getRoundListHandle
    }
}
export default useRetailOverview
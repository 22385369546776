
import { defineComponent, ref, reactive, onMounted } from 'vue';
import { payoutRoundGenerate, deletePayoutRound } from '@/API/claim/claimWS';
import useWSPayoutData from '@/hooks/claim/useWSPayoutData';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
export default defineComponent({
    emits: ['close-sub-visible'],
    props: {
        isVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        // isDelete: {
        // 	type: Boolean,
        // 	required: true,
        // 	default: false
        // }
    },
    setup(props, context) {
        const {
            selectparams,
            tableHeight,
            columns,
            data,
            paginationObj,
            listParams,
            getTableData,
            pageChange,
            sizeChange,
        } = useWSPayoutData();
        // // 设置抽屉显示状态
        const showDrawer = (props as any).isVisible;
        // 设置抽屉关闭
        const onClose = () => {
            context.emit('close-sub-visible', { isVisible: false });
        };
        /* 选择条件生成功能区域 start */
        const roleList = reactive([{ label: 'Financing', value: 'AFC' }]);
        const MonthList = reactive([
            { label: 'Jan', value: '1' },
            { label: 'Feb', value: '2' },
            { label: 'Mar', value: '3' },
            { label: 'Apr', value: '4' },
            { label: 'May', value: '5' },
            { label: 'Jun', value: '6' },
            { label: 'Jul', value: '7' },
            { label: 'Aug', value: '8' },
            { label: 'Sep', value: '9' },
            { label: 'Oct', value: '10' },
            { label: 'Nov', value: '11' },
            { label: 'Dec', value: '12' },
        ]);
        const date = new Date();
        const yearCur = `${date.getFullYear()}`;
        const yearPrev = `${date.getFullYear() - 1}`;
        const yearNext = `${date.getFullYear() + 1}`;
        const YearList = reactive([yearPrev, yearCur, yearNext]);
        // 根据选择条件校验规则
        const rules = reactive<any>({
            role: false,
            type: false,
            year: false,
            month: false,
        });
        const selectSize = ref<number>(20);
        // 选择框失去焦点触发校验规则，判断校验提示显示与否
        const handleBlur = (val: any, name: string) => {
            rules[name] = val.trim() === '' ? true : false;
        };
        /* 选择条件生成功能区域 end */
        // 页面加载渲染表格数据
        onMounted(() => {
            getTableData();
        });
        //2.payoutRound生成接口传参
        const generateParams = reactive({
            afcLcType: '',
            month: 0,
            type: 'WS',
            year: 2021,
        });
        // 调生成payoutRound接口，成功后刷新表格，失败弹出错误提示
        const generateData = () => {
            generateParams.afcLcType = selectparams.roleVal;
            payoutRoundGenerate(generateParams).then((res) => {
                if (res && res?.code == '0') {
                    message.success('payout round generate success！');
                    getTableData();
                } else {
                    message.error('payout round generate fail！');
                }
                selectparams.roleVal = 'AFC';
                selectparams.typeVal = 'WS';
                selectparams.yearVal = undefined;
                selectparams.monthVal = undefined;
            });
        };
        // 选择器内容校验
        const checkSelectVal = () => {
            let count = 0;
            for (const key in selectparams) {
                if (
                    selectparams[key] === '' ||
                    selectparams[key]['label'] === ''
                ) {
                    rules[`${key.substring(0, key.length - 3)}`] = true;
                }
                if (
                    selectparams[key] !== '' &&
                    selectparams[key]['label'] !== ''
                ) {
                    count++;
                }
            }
            if (count === Object.getOwnPropertyNames(selectparams).length) {
                return true;
            }
        };
        // 校验并调payoutRound生成接口
        const generateHandle = () => {
            if (checkSelectVal()) {
                generateParams.month = Number(selectparams.monthVal);
                generateParams.year = Number(selectparams.yearVal);
                generateData();
            }
        };
        // 删除当前round
        const deleteHandle = (id: string) => {
            deletePayoutRound({ id: id }).then((res) => {
                if (res && res?.code === '0') {
                    message.success(res.message);
                    // 成功：重新刷新页面
                    listParams.current = 0;
                    paginationObj.current = 1;
                    getTableData();
                } else {
                    Modal.warning({
                        title: 'Tips',
                        content: res.message,
                        okText: 'Confirm',
                        centered: true,
                    });
                }
            });
        };
        return {
            showDrawer,
            selectSize,
            selectparams,
            MonthList,
            roleList,
            YearList,
            onClose,
            handleBlur,
            generateHandle,
            tableHeight,
            columns,
            data,
            deleteHandle,
            pageChange,
            sizeChange,
            paginationObj,
            rules,
            getTableData,
            listParams,
        };
    },
});

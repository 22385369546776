
import { defineComponent, ref, reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import PayoutRoundDetail from "./PayoutRound/index.vue";
import UploadDetail from "./UploadDetail/index.vue";
import useWSOverview from "@/hooks/claim/useWSOverview";
import {
  deleteReimbursement,
  payoutRoundSubmit,
  downloadChecking,
  finalResult,
} from "@/API/claim/claimWS";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import downloadFile from "@/utils/claim/downloadFile";
import axios from "axios";
export default defineComponent({
  components: {
    PayoutRoundDetail,
    UploadDetail,
  },
  setup() {
    const router = useRouter();
    const {
      payoutVal,
      statusVal,
      selectSize,
      payoutList,
      statusList,
      paginationObj,
      claimListParams,
      tableHeight,
      columns,
      claimListData,
      getTableData,
      pageChange,
      sizeChange,
      getTableDateHandle,
      getRoundListHandle,
    } = useWSOverview();
    // 用户角色设置及判断
    const curRole = ref<string>("");
    // 设置isVisible状态
    const isVisible = ref<boolean>(false);
    const isUploadVisible = ref<boolean>(false);
    // 关闭抽屉
    const closeSub = (param: { isVisible: boolean }) => {
      isVisible.value = param.isVisible;
    };
    const closeUpload = (param: { isUploadVisible: boolean }) => {
      isUploadVisible.value = param.isUploadVisible;
    };
    // 功能按钮区域
    // payout round
    const generateRoundHandle = () => {
      isVisible.value = true;
    };
    const uploadHandle = (role: string) => {
      curRole.value = role;
      isUploadVisible.value = true;
    };
    // 表格复选框逻辑
    const state = reactive<any>({
      selectedRowKeys: [], // Check here to configure the default column
      selectedRows: [],
    });
    const start = () => {
      // ajax request after empty completing
      state.selectedRowKeys = [];
      state.selectedRows = [];
    };
    const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
    };
    // Submit for checking接口调用
    const submitCheckingParams = reactive({ ids: [] });
    const submitCheckingHandle = () => {
      const selectedArr: any = [];
      if (state.selectedRows.length < 1) {
        Modal.warning({
          title: "Tips",
          content: "Please select one and click again!",
          okText: "Confirm",
          centered: true,
        });
        return;
      }
      Modal.confirm({
        title: "Tips",
        content: "Do you want to submit selected payout round?",
        okText: "Confirm",
        centered: true,
        onOk() {
          for (let i = 0; i < state.selectedRows.length; i++) {
            selectedArr.push(state.selectedRows[i].id);
          }
          submitCheckingParams.ids = selectedArr;
          payoutRoundSubmit(submitCheckingParams).then((res) => {
            if (res.code === "0") {
              message.success(res.message);
              // 刷新页面
              getTableData();
            } else {
              Modal.error({
                title: "Tips",
                content: res.message,
                centered: true,
              });
            }
          });
        },
      });
    };
    // download Checking 接口调用
    const downloadCheckingHandle = () => {
      if (
        state.selectedRowKeys.length === 0 ||
        state.selectedRowKeys.length > 1
      ) {
        Modal.warning({
          title: "Tips",
          content: "Please select one and click again!",
          centered: true,
        });
      } else if (state.selectedRowKeys.length === 1) {
        const payoutRoundId = state.selectedRows[0].id;
        downloadChecking(payoutRoundId).then((res) => {
          if (res.code === "50068") {
            Modal.warning({
              title: "Tips",
              content: res.message,
              centered: true,
            });
          } else {
            const params = {
              url: `/claimapi/payout/round/ws/downloadZip/${payoutRoundId}`,
              method: "get",
              fileName: `${+new Date()}.zip`,
            };
            downloadFile(params, "application/zip; char-set=UTF-8");
          }
        });
      }
    };
    const refreshTable = () => {
      getTableData();
      getRoundListHandle();
    };
    // finalResult 接口调用
    const finalResultHandle = () => {
      if (state.selectedRowKeys.length === 0) {
        Modal.warning({
          title: "Tips",
          content: "Please select at least one and click again!",
          centered: true,
        });
      } else {
        console.log(Array.isArray(state.selectedRowKeys));
        const params = {
          ids: state.selectedRowKeys,
          status: 11,
        };
        finalResult(params).then((res) => {
          refreshTable();
          if (res.code === 0) {
            message.success(res.message);
          } else {
            Modal.warning({
              title: "Tips",
              content: res.message,
              centered: true,
            });
          }
        });
      }
    };
    const viewHandle = (id: string) => {
      router.push({ path: "/claim/Ws/wsDetail", query: { id: id } });
    };
    // 删除数据接口调用
    const deleteHandle = (id: string) => {
      deleteReimbursement({ id: id }).then((res) => {
        if (res.code === "0") {
          message.success(res.message);
          getTableData();
          getRoundListHandle();
        } else {
          Modal.error({
            title: "Tips",
            content: res.message,
            centered: true,
          });
        }
      });
    };

    onMounted(() => {
      getTableData();
    });

    return {
      curRole,
      payoutVal,
      payoutList,
      statusList,
      statusVal,
      selectSize,
      generateRoundHandle,
      uploadHandle,
      submitCheckingHandle,
      downloadCheckingHandle,
      finalResultHandle,
      getTableDateHandle,
      start,
      onSelectChange,
      columns,
      claimListData,
      getTableData,
      viewHandle,
      deleteHandle,
      isVisible,
      isUploadVisible,
      closeSub,
      closeUpload,
      tableHeight,
      pageChange,
      sizeChange,
      paginationObj,
      claimListParams,
      refreshTable,
      ...toRefs(state),
    };
  },
});
